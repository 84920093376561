<template>
  <div>
    <v-dialog v-model="dialog" persistent width="650">
      <v-card>
        <v-card-title>Print Modal</v-card-title>
        <v-card-text>
          <!-- <h3>What do you want to print?</h3> -->

          <div class="d-flex">
            <confirm-invoice-print-modal :item="item" />
            <v-btn
              small
              class="ml-2"
              color=""
              @click="print_statement_of_account(item)"
              ><v-icon>mdi-list-box</v-icon>Statement of Account</v-btn
            >
            <print-delivery-receipt-modal :item="item" />
            <!-- <v-btn
              small
              class="ml-2"
              color=""
              @click="print_delivery_receipt(item)"
              ><v-icon>mdi-format-list-numbered</v-icon>Delivery Receipt</v-btn
            > -->
          </div>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn color="" @click="submit" :loading="loading">Submit</v-btn> -->
          <v-btn @click="$emit('close')"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">printModalCard.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.common.js";
import confirmInvoicePrintModal from "../Payment/confirmInvoicePrintModal.vue";
import PrintDeliveryReceiptModal from "./printDeliveryReceiptModal.vue";
export default {
  components: { confirmInvoicePrintModal, PrintDeliveryReceiptModal },
  props: ["item"],
  data() {
    return {
      dialog: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    print_statement_of_account(item) {
      let x = window.confirm(
        "Are you sure you want to proceed with your request?"
      );
      if (x) {
        let url =
          this.default_server_url +
          "/print_statement_of_account/" +
          item.customer.id;
        window.open(url);
      }
    },
    submit() {
      return;
    },
  },
};
</script>

<style lang="scss" scoped></style>
